<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <h1>Banner</h1>
        <v-form @submit.prevent="submit()">
          <v-text-field
            v-model="banner.titulo"
            label="Titulo"
            name="titulo"
            type="text"
            required
            :rules="rules"
          />
          <v-text-field
            v-model="banner.url"
            label="URL"
            name="url"
            type="text"
          />

          <v-file-input
            v-if="!$route.params.id"
            ref="file"
            v-model="file"
            chips
            label="Imagem"
          />

          <v-btn
            color="primary"
            type="submit"
          >
            Salvar
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    data: () => ({
      banner: {},
      file: null,
      rules: [
        v => !!v || 'Esse campo é obrigatório',
      ],
    }),
    mounted () {
      if (this.$route.params.id) {
        this.load()
      }
    },
    methods: {
      load () {
        this.$http.get(`/banners/${this.$route.params.id}`)
          .then(resp => {
            this.banner = resp.data
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      submit () {
        if (this.$route.params.id) {
          this.put()
        } else {
          this.post()
        }
      },
      getFormData () {
        const formData = new FormData()
        formData.append('imagem', this.file)
        formData.append('titulo', this.banner.titulo)
        formData.append('url', this.banner.url)
        return formData
      },
      post () {
        this.$http.post('/banners', this.getFormData(), { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.$router.push('/pages/banners')
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      put () {
        this.$http.put('/banners/', this.banner)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.$router.push('/pages/banners')
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
    },
  }
</script>
